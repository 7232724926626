exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-corporate-policy-js": () => import("./../../../src/pages/corporate-policy.js" /* webpackChunkName: "component---src-pages-corporate-policy-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leasing-js": () => import("./../../../src/pages/leasing.js" /* webpackChunkName: "component---src-pages-leasing-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */)
}

